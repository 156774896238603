import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GoogleOAuthProvider,
  googleLogout,
  GoogleLogin,
} from "@react-oauth/google";
import jwt_decode from "jwt-decode";

import { Login,  vinanti } from "../../data/data";
import {
  toggleLogin,
  userInfo,
  updateCustomSettings,
  StateUpdateAction,
  updateTasksList,
} from "../../store/actions";
import { Button } from "../utils/button";

const CLIENT_ID =
  "374169744841-5bhijqlfbjmca09soll74o4k3j3d7jvb.apps.googleusercontent.com";

export const LoginTab = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [user_name, setUser_name] = useState("Guest");
  const [img_url, setImg_url] = useState(
    "https://www.flaticon.com/premium-icon/guests_3044552",
  );
  const loggedIn = useSelector((state) => state.log_in_status);
  const dispatch = useDispatch();

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const onSignIn = (googleUser) => {
    const userObject = jwt_decode(googleUser.credential);
    localStorage.setItem("user", JSON.stringify(userObject));
    const { name, sub, picture, email } = userObject;
    setImg_url(picture);
    setUser_name(name);
    // login to admin io with google credits
    Login({ name, sub, picture, email }).then(async (data) => {
      dispatch(toggleLogin(true)); // this.setState({ loggedIn: true });
      props.data.loggedIn(true);
      var payload = {
        user_id: sub,
        img_url: picture,
        user_name: name,
        user_email: email,
        auth_token: data.data["auth-token"],
      };

      dispatch(userInfo(payload));

      var meta_data = await vinanti("", "metadata", payload.auth_token, {
        email: payload.user_email,
      });

      dispatch(
        StateUpdateAction(
          "META_DATA",
          meta_data && meta_data.length > 0 ? meta_data[0] : null,
        ),
      );

      var custom_setts = await vinanti("list", "custom", payload.auth_token, {
        email: payload.user_email,
      });
      dispatch(updateCustomSettings(custom_setts?.[0]));

      // let tasks = await vinanti("list", "task", payload.auth_token, {
      //   email: payload.user_email,
      // });
      // dispatch(updateTasksList(tasks));
    });
  };

  const signOut = () => {
    localStorage.setItem("user", null);
    dispatch(toggleLogin(false)); // this.setState({ loggedIn: true });
    googleLogout();
  };

  useEffect(() => {}, []);

  return (
    <>
      {loggedIn ? (
        <>
          <div
            style={{ display: "flex", cursor: "pointer", height: "40px" }}
            onClick={toggleCollapse}
          >
            <img
              src={img_url}
              style={{ height: "40px", padding: 0, borderRadius: "50%" }}
              alt=""
            />
            <div
              className="hidden md:visible lg:flex"
              style={{ padding: "10px 10px", color: "white" }}
            >
              {user_name}
            </div>
          </div>
          {isOpen ? (
            <div className="Glogout">
              <Button text="Logout" onclick={signOut} />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <GoogleOAuthProvider clientId={`${CLIENT_ID}`}>
          <GoogleLogin
            onSuccess={onSignIn}
            onFailure={signOut}
            cookiePolicy="single_host_origin"
            auto_select={true}
            theme="filled_black"
            text="continue_with"
            useOneTap={true}
            // size="medium"
          />
        </GoogleOAuthProvider>
      )}
    </>
  );
};
