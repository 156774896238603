import React from "react";
import { TimeHomePage } from "../utils/Currrent_time.tsx";
import { MaterialIcon } from "../utils/material_icon.js";
import { Rein } from "../rein/index.tsx";

type Props = { change_wallpaper: () => null };

export const FooterBar = ({ change_wallpaper }: Props) => {
  return (
    <footer className="grid h-[20px] grid-cols-3 grid-rows-1 bg-slate-600">
      <div className="col-start-1 mx-2 flex gap-1">
        <div> Powered by duck bot 🦆 </div>
        <Rein />
      </div>
      <div className="col-start-2"></div>
      <div className="col-start-3 mx-2 flex flex-row-reverse gap-1.5">
        <TimeHomePage size={"text-sm"} />
        <MaterialIcon
          icon="wallpaper"
          size={20}
          onclick={change_wallpaper}
          cursor={"pointer"}
        />
      </div>
    </footer>
  );
};
