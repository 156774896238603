import { Component } from "react";
import "./App.css";
// import CustomRightClick from "./components/experiment/custom_right_click/custom_right_click";
import { Pagex } from "./pages/index";
import NavTailwind from "./components/nav_bar/nav_bar_tailwind";
// Readme page Before login
// import Markdown from "react-markdown";
// import remarkGfm from "remark-gfm"; // Parse Github flavoured markdown
// import rehypeRaw from "rehype-raw"; // parse HTML
import markdown_file from "./data/README.md";

import bg_Img from "./data/images/dark_color_vivid_bg.png";
import { splash_imgae } from "./data/data";
import { FooterBar } from "./components/footer_bar/footer_bar";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth_token: "empty..",
      user: "Guest",
      loggedIn: false,
      bg_img: bg_Img,
      readme: "",
    };
    this.signOut = this.signOut.bind(this);
    this.change_wallpaper = this.change_wallpaper.bind(this);
  }
  componentDidMount() {
    // it gets called twice due to react strict mode in dev only. [ works fine in prod]

    console.log(" APP >> Did_Mount");
    this.change_wallpaper();
    try {
      fetch(markdown_file)
        .then((response) => response.text())
        .then((text) => {
          this.setState({ readme: text });
          // console.log("doc fetched", text);
        });
    } catch (e) {
      console.log(e);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.auth_token !== prevState.auth_token) {
      console.log(" APP >> Did_update >> ", this.state.auth_token);
    }
  }
  change_wallpaper = () => {
    splash_imgae().then((m) => {
      if (m && m[0]) {
        this.setState({ bg_img: m[0]?.urls?.full });
      }
    });
  };

  auth_info = (token, user) => {
    this.setState({ auth_token: token, user: user });
  };
  loggedIn = (loggedIn) => {
    this.setState({ loggedIn });
  };

  signOut = () => {
    this.setState({
      auth_token: "empty..",
      user: "Guest",
      loggedIn: false,
    });
  };
  render() {
    return (
      <div key={"MAIN_APP"} className="App">
        <NavTailwind
          data={{
            loggedIn: this.loggedIn,
          }}
        />
        <main
          className="main-wrapper"
          role="main"
          style={{ backgroundImage: `url(${this.state.bg_img})` }}
        >
          <section id="Admin-Section">
            {this.state.loggedIn === true ? (
              <>
                <Pagex />
                {/* <CustomRightClick /> */}
              </>
            ) : (
              <div className="login-page flex h-full justify-center overflow-x-scroll bg-white">
                <div>
                  {/* <Markdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    children={this.state.readme}
                  /> */}
                </div>
              </div>
            )}
          </section>
        </main>
        <FooterBar change_wallpaper={this.change_wallpaper} />
        
      </div>
    );
  }
}

export default App;
