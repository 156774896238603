import { LinkSchema } from "../../../type";
import { MaterialIcon } from "../../../components/utils/material_icon";
import "./css/hyperlink.css";
// import { useEffect } from "react";

export function HyperLink({ d = null }: { d: LinkSchema | null }) {
  const hlink = d ? d.link_hyperlink : "https://www.demoblaze.com/";
  // useEffect(() => {
  //     fetch(link).then(console.log);
  // }, []);
  return (
    <>
      <div className="link-hyperlink-wrapper">
        <div className="link-hyperlink-link">
          <a
            href={hlink}
            target="_blank"
            rel="noreferrer"
            style={{
              width: "200px",
              display: "block",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {hlink}
          </a>
          <MaterialIcon
            icon="content_copy"
            onclick={() => navigator.clipboard.writeText(hlink)}
            cursor={"pointer"}
          />
        </div>
        <iframe
          title={hlink}
          width="400"
          height="250"
          className="link-preview"
          src={hlink}
          scrolling="no"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}
