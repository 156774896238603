import { Excalidraw } from "@excalidraw/excalidraw";
import React from "react";


type Props = {};

function Exacalidraw({}: Props) {
  return (
    <div className="w-full h-full">
      <Excalidraw />
    </div>
  );
}

export default Exacalidraw;
