import { Link } from "../link";
import { useState, useEffect } from "react";
import { vinanti } from "../../../data/data";
import { Loading } from "../../../components/utils/loading";
import { useAppSelector } from "../../../store/store";
import { LinkSchema } from "../../../type";

export const Duplicates = ({ d }: { d: LinkSchema | null }) => {
  const [duplicates, setDuplicates] = useState<LinkSchema[] | null>(null);
  const user_info = useAppSelector((state) => state.user_info);
  const [searching_dups, setSearching_dups] = useState(false);

  const search_dups = async () => {
    setSearching_dups(true);

    if (!d?.link_description) return;

    let online_links = await vinanti(
      "duplicate_search",
      "link",
      user_info.auth_token,
      {
        email: user_info.user_email,
        search: d?.link_description,
      },
    );
    console.log("list of duplicate links", online_links);
    setSearching_dups(false);
    setDuplicates(online_links);
  };

  useEffect(() => {
    search_dups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <h6>Possible Duplicates</h6>
        {searching_dups ? <Loading /> : <></>}
        {duplicates?.length ? (
          <ol>
            {duplicates.map((d, i) => (
              <li>
                {/* <div> {Math.round(d?.score * 100)/100} </div> */}
                <Link
                  d={d}
                  // i={Math.round(d?.score * 100) / 100}
                />
              </li>
            ))}
          </ol>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
