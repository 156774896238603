// import { Provider } from "react-redux";
// import { kardzStore } from "./store";
// import Toolbar from "./src/components/toolbar";
// import { Kardz } from "./kardz/src/kardz";

const KardzApp = () => {
  return (
    // <div className="h-full w-full flex items-center justify-center bg-white text-4xl font-bold text-black">
    //   Work in Progress
    // </div>
    <iframe
      title="Iframe Site Viewer"
      src={"https://kardz.vercel.app/"}
      width={"100%"}
      height={"100%"}
    ></iframe>
  );
};

export default KardzApp;
