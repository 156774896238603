import React from "react";
import { useSelector } from "react-redux";

import { Tasks } from "./Tasks/tasks";
import { Home } from "./Home/home";
import { Home2 } from "./Home/home2";
import { Users } from "./Users/Users";
import { Projects } from "./Projects/projects";
import { Apps } from "./Apps/Apps";
import { Leetcode } from "./Leetcode/Leetcode";
import { ProfileIo } from "./Profile_io/profile_io";
import { CustomSettings } from "./Custom_Settings/custom";
import { DocsAnalyser } from "./Docs_analyser/docs_analyser";
import { StoryIo } from "./Story_io/story_io";
import { RitualIo } from "./Ritual_io/ritual_io";
import { IFrame } from "./Extra_pages/iframe";
import { TodayList } from "./Today/today_list";
import { TimeLineIo } from "./Timeline/timeline_page";
import { LiveVerse } from "./live/live";
import KardzApp from "./Kardz";
import GallaryApp from "./Extra_pages/gallary/gallary";
import LinksIOWrapper from "./Link_io/linksIO_wrapper";
import CanvasApp from "./Extra_pages/canvas_demo";
import PlannerX from "./planner";
import Exacalidraw from "./exacalidraw";

export const Pagex = () => {
  const page = useSelector((state) => state.page_live);

  const components = {
    Tasks: Tasks,
    // Tasks: TodayList,
    // Tasks_today : Tasks_today,
    Projects: Projects,
    Users: Users,
    Apps: Apps,
    Home: Home,
    Home2: Home2,
    Kardz: KardzApp,
    Leetcode: Leetcode,
    Link_io: LinksIOWrapper,
    Profile_io: ProfileIo,
    Story_io: StoryIo,
    Timeline_io: TimeLineIo,
    Docs_analyser: DocsAnalyser,
    Custom_Settings: CustomSettings,
    Ritual_io: RitualIo,
    IFrame: IFrame,
    LiveVerse: LiveVerse,
    Gallary: GallaryApp,
    Canvas: CanvasApp,
    PlannerX: PlannerX,
    Exacalidraw : Exacalidraw
  };
  //
  var Xpage = components[page];
  // var  VarDisplay = Tasks;

  return <Xpage />;
};
