import React from "react";
import { Duplicates } from "./link_types/type_duplicates";
import { HyperLink } from "./link_types/type_hyperlink";
import { LinkSchema } from "../../type";

const None = () => {
  return <></>;
};

export const LinkTypeRouter = ({
  page = "None",
  d = null,
}: {
  page: string;
  d: LinkSchema | null;
}) => {
  const getComponent = () => {
    switch (page) {
      case "None":
        return <None />;
      case "Hyperlink":
        return <HyperLink d={d} />;
      case "Duplicates":
        return <Duplicates d={d} />;
      //   case "glink":
      //     return <Glink d={d} />;
      default:
        return <></>;
    }
  };
  return <> {getComponent()}</>;
};
