import React, { useCallback, useState } from "react";
import { TaskSchema, TypePayloadAction } from "../../type";
import { Button, Input } from "react-utility-yard";
import { useAppDispatch } from "../../store/store";
import { tasks_new_task } from "../../store/actions/actionTypes";
import { vinanti } from "../../data/data";
import { timeToNumber, timeToString } from "./task_helper";

type Props = {
  task?: TaskSchema;
};

const default_task: TaskSchema = {
  task_name: "",
  task_length: 600,
  date_scheduled: "",
  date_expire: "",
  towards_project: "",
  sub_tasks: [],
  status: false,
  task_details: "",
};

export const TaskAdder = ({ task = default_task }: Props) => {
  const [Task, setTask] = useState<TaskSchema>(task);
  const dispatch = useAppDispatch();
  const dispatch_action = useCallback(
    ({ type, payload }: TypePayloadAction) =>
      dispatch({ type: type, payload: payload }),
    [dispatch],
  );
  const newTask_submit = async () => {
    let raw_task = { ...Task };
    if (raw_task.task_name === "") raw_task.task_name = "Empty task";
    if (raw_task.date_expire === "")
      raw_task.date_expire = new Date().toLocaleDateString();
    if (raw_task.date_scheduled === "")
      raw_task.date_scheduled = new Date().toLocaleDateString();
    console.log(raw_task.date_expire);
    const data = await vinanti("new", "task", null, {
      ...raw_task,
    });
    if (data?.length)
      dispatch_action({ type: tasks_new_task, payload: data[0] });
    setTask(default_task);
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center gap-1">
        <div className="flex items-center justify-center gap-1">
          <Input
            className="bg-slate-200"
            editOn={true}
            size="md"
            placeholder="Enter New Task Name"
            value={Task?.task_name}
            onchange={(value: any) => setTask({ ...Task, task_name: value })}
          />
          <Input
            className="bg-slate-200"
            editOn={true}
            size="sm"
            type="time"
            placeholder="Task length"
            value={timeToString(Task?.task_length)}
            onchange={(value: any) =>
              setTask({ ...Task, task_length: timeToNumber(value) })
            }
          />
        </div>
        <div className="flex flex-wrap items-center justify-center gap-1">
          <Input
            className="bg-slate-200"
            editOn={true}
            size="sm"
            type="datetime-local"
            placeholder="Scheduled Date"
            value={Task?.date_scheduled}
            onchange={(value: any) =>
              setTask({ ...Task, date_scheduled: value })
            }
          />
          <h6> To </h6>
          <Input
            className="bg-slate-200"
            editOn={true}
            size="sm"
            type="datetime-local"
            placeholder="Expiry Date"
            // onFocus={"(this.type='date')"}
            value={Task?.date_expire}
            onchange={(value: any) => setTask({ ...Task, date_expire: value })}
          />
        </div>
        <div className="flex items-center justify-center gap-1">
          <Input
            className="bg-slate-200"
            editOn={true}
            value={Task?.towards_project}
            placeholder="Project"
            size="sm"
            onchange={(value: any) =>
              setTask({ ...Task, towards_project: value })
            }
          />
          <Button
            text={"Create New"}
            onclick={() => newTask_submit()}
            // loading={busy}
          />
        </div>
      </div>
    </div>
  );
};
